import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Momoya",
  pageDescription:
    "See how a top multi-location sushi restaurant in New York leverages SpotOn to simplify order taking and payment processing, and boost revenue in the process.",
  title: "Managing 3 popular Japanese restaurants",
  subTitle: `How Momoya streamlines operations across multiple locations with SpotOn.`,
  businessName: "Momoya",
  industry: "Restaurant & hospitality, casual dining",
  location: "New York City, New York",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.ORDER,
    spotonProducts.SERVE,
  ],
  description:
    "With 130 employees and 3 location, Momoya is one of New York's top sushi restaurants, serving traditional and inventive Japanese cuisine. Looking to streamline operations, owner KwangHo Lee leverages SpotOn technology to manage multiple location and boost his overall revenue.",
  quoteData: {
    imgName: "momoya-with-logo.png",
    quote: `“SpotOn makes my job much easier. I don’t have to be at the physical location to access data—I can be anywhere. For anyone with multiple locations, this kind of system is a must. The handheld device is essential for restaurants with outdoor seating. It makes our closeout much easier than before.”`,
    personName: "KwangHo Lee",
    personTitle: "Owner, Momoya",
  },
  goal: `After taking over his sister's booming Japanese restaurant, Lee needed a POS system that could work across multiple locations, simplify order-taking and payment processing, integrate with delivery apps, and provide remote access to reports.`,
  solution: `With SpotOn's cloud-based platform, Lee can monitor his restaurants from anywhere. And while SpotOn Serve handhelds increase sales and tips with easy patio-guest management, the ItsACheckMate integration feeds orders directly into the POS.`,
  solutionReadMore: {
    text: `Read more about their story.`,
    url: `https://spoton.com/blog/from-science-to-sushi/`,
  },
  results: {
    title: `The results`,
    stats: [
      {
        title: `10%`,
        description: `increase in daily orders`,
      },
      {
        title: `4%`,
        description: `increase in average tips`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Steak and tech done to perfection",
    imageName: "deblaze-grill.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-07",
    href: "/case-studies/deblaze-grill",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: '"Slow" food meets fast tech',
    imageName: "blue-barn.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-06-24",
    href: "/case-studies/blue-barn",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "Selling tacos, turning tables",
    imageName: "la-chiva-loka.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-14",
    href: "/case-studies/la-chiva-loka",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
